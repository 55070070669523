<template>
  <main class="dp-introduction">
    <header class="dp-introduction-header">
      <figure>
        <img
          src="/assets/img/drip-pod/introduction/pic_header.webp"
          alt="きょうも、あしたも、ふだんを好い日に。DRIP POD YOUBI" />
      </figure>
    </header>

    <section
      ref="connectionButtonVisibilityTrigger"
      class="dp-introduction-section">
      <h1 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_coffee_time.webp"
          alt="コーヒータイムがもっと自由になれば、日々の暮らしがもっと豊かになる。" />
      </h1>

      <p>
        「<span class="dp-introduction-label-en">Drip Pod Youbi</span
        >」と「<span class="dp-introduction-label-en">Coffee Creation</span
        >」アプリを組み合わせれば、自分の好みやその日の気分にあわせて、あなたをエスコート。
      </p>

      <p>
        プロの抽出レシピで、ひとつのカプセルから広がる様々な味わいに出会えます。
      </p>

      <p>
        コーヒーの選び方をもっと自由に、<br />味わいをさらに豊かにしてくれます。
      </p>
    </section>

    <section class="dp-introduction-section">
      <h2 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_professionals_recipe.webp"
          alt="Professional's Recipe" />
      </h2>

      <p>
        さまざまな抽出競技会でチャンピオンに輝くUCCのプロフェッショナルが監修したオリジナル抽出レシピです。
        同じコーヒー豆（<span class="dp-introduction-label-en">Drip Pod</span
        >専用カプセル）でも、
        「蒸らし」「抽出時間」「注湯スピード」など異なる抽出レシピにすることで、様々に変化する味わいを楽しむことができます。
      </p>

      <p>
        <span class="dp-introduction-label-en"
          ><span class="dp-introduction-label-en">Coffee Creation</span></span
        >アプリを使用すると、
        プロのオリジナル抽出レシピを気分やシーンに合わせて気軽に楽しむことができます。
      </p>

      <p class="dp-introduction-section__note">
        *PROFESSIONAL’s RECIPEは、<span class="dp-introduction-label-en"
          >Drip Pod Youbi</span
        >のオリジナル機能です。
      </p>

      <figure>
        <img
          src="/assets/img/drip-pod/introduction/pic_professional_recipe.webp"
          alt="" />
      </figure>

      <div class="dp-introduction-section__balloon">
        <p>
          私たちは、バリスタチャンピオンシップなどの競技会で、コーヒー豆の個性に合わせて“抽出レシピ”を変えることで、引き出したい味わいをコントロールしています。
        </p>

        <p>
          甘みを引き出したいときは、高めの湯温で蒸らし時間を多めにとったり、雑味を減らしたいときは後半の抽出は低温でサッと淹れるなど様々なテクニックがあるんですよ。（UCCコーヒーアカデミー講師
          村田果穂）
        </p>
      </div>
    </section>

    <section class="dp-introduction-section">
      <h2 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_merit.webp"
          alt="Coffee Creationでできること" />
      </h2>

      <figure>
        <img src="/assets/img/drip-pod/introduction/pic_merit01.webp" alt="" />
      </figure>

      <h3 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_merit01.webp"
          alt="アプリ連携でプロの抽出レシピが楽しめる" />
      </h3>

      <p>
        同じカプセル（コーヒー豆）でも、抽出レシピによって、フルーティさを際立たせたり、さっぱりとした味わいで抽出したり、味わいに変化をもたせることができます。<br />
        ひとつのカプセルから広がる味わい、広がるコーヒーの世界をお楽しみいただけます。
      </p>

      <figure>
        <img src="/assets/img/drip-pod/introduction/pic_merit02.webp" alt="" />
      </figure>

      <h3 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_merit02.webp"
          alt="色々な選び方ができる" />
      </h3>

      <p>
        レシピ名から探す、カプセルから探す、レシピを監修した講師から探すなど、
        <span class="dp-introduction-label-en">Coffee Creation</span
        >アプリを使えば、様々な方法でレシピを探せます。さっそくお気に入りのレシピを見つけてみては？
      </p>

      <figure>
        <img src="/assets/img/drip-pod/introduction/pic_merit03.webp" alt="" />
      </figure>

      <h3 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_merit03.webp"
          alt="お気に入りのプロのレシピをマシンに登録" />
      </h3>

      <p>
        抽出レシピを試すなかでお気に入りのものを見つけたら、 是非「<span
          class="dp-introduction-label-en"
          >Drip Pod Youbi</span
        >」本体に登録してみましょう。<br />
        アプリを立ち上げなくても、本体の「<span class="dp-introduction-label-en"
          >Favorite</span
        >」ボタンを押すだけでお気に入りの一杯が楽しめます。
      </p>

      <a
        :href="$customUrlScheme.page('external', `url=${MORE_BUTTON_HREF}`)"
        class="dp-introduction-button"
        v-ripple="$utilities.getRippleOptions('light')">
        <img
          src="/assets/img/drip-pod/introduction/button_more.webp"
          alt="Drip Podをもっと詳しく知る" />
      </a>
    </section>

    <section class="dp-introduction-section">
      <h2 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_connection.webp"
          alt="アプリと連携するには？" />
      </h2>

      <p>
        スマートフォンと「<span class="dp-introduction-label-en"
          >Drip Pod Youbi</span
        >」を用意して、「<span class="dp-introduction-label-en">Drip Pod</span
        >と連携する」ボタンを押しましょう！画面の説明に従い、「<span
          class="dp-introduction-label-en"
          >Drip Pod Youbi</span
        >」とアプリを連携してください。
      </p>
    </section>

    <section class="dp-introduction-section">
      <figure>
        <img src="/assets/img/drip-pod/introduction/pic_about.webp" alt="" />
      </figure>

      <h2 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_about.webp"
          alt="Drip Podとは？" />
      </h2>

      <p>
        <span class="dp-introduction-label-en">Drip Pod</span
        >（ドリップポッド）とは、コーヒーひと筋90年のUCCが手掛けた、カプセル式のドリップコーヒーシステムです。
      </p>

      <figure>
        <img src="/assets/img/drip-pod/introduction/pic_easy.webp" alt="" />
      </figure>

      <h2 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_easy.webp"
          alt="プロのハンドドリップをボタンひとつで。" />
      </h2>

      <p>
        おいしさの秘密は、しっかりと“蒸らし”ができるカプセルの中に。プロのハンドドリップの技を再現した「湯温」「抽出速度」「蒸らし時間」により、ボタンひとつで本格的なコーヒーが淹れられます。
      </p>

      <figure>
        <img
          src="/assets/img/drip-pod/introduction/pic_tasty.webp"
          alt="Coffee Belt（南緯25度から北緯25度の地域）の画像" />
      </figure>

      <h2 class="dp-introduction-section-title">
        <img
          src="/assets/img/drip-pod/introduction/title_tasty.webp"
          alt="世界中を旅するように味わえる" />
      </h2>

      <p>
        熟練のコーヒー鑑定士が世界中の生産地から厳選したコーヒーを一杯分ずつ密封パック。<br />
        12種類の定番カプセルラインアップに加え、旬の味わいを楽しめる期間限定のスペシャルティコーヒーも楽しめます。<br />
        きっとお好みの味が見つかるはずです。<br />
        また、シーンや気分にあわせて、紅茶やお茶もお楽しみいただけます。
      </p>

      <a
        :href="$customUrlScheme.page('external', `url=${MORE_BUTTON_HREF}`)"
        class="dp-introduction-button"
        v-ripple="$utilities.getRippleOptions('light')">
        <img
          src="/assets/img/drip-pod/introduction/button_more.webp"
          alt="Drip Podをもっと詳しく知る" />
      </a>

      <figure>
        <img
          src="/assets/img/drip-pod/introduction/logo_dp.webp"
          alt="Drip Podのロゴ" />
      </figure>
    </section>

    <transition name="fade" mode="out-in">
      <footer v-if="connectionButtonVisibility" class="dp-introduction-footer">
        <button
          type="button"
          class="dp-introduction-connection-button"
          v-ripple="$utilities.getRippleOptions('light')"
          @click="connectDripPod">
          <img
            src="/assets/img/drip-pod/introduction/button_connection.webp"
            alt="Drip Pod Youbiと連携する" />
        </button>
      </footer>
    </transition>
  </main>
</template>

<script>
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  onBeforeUnmount
} from '@vue/composition-api';

export default defineComponent({
  setup: (props, context) => {
    const MORE_BUTTON_HREF = 'https://www.ucc.co.jp/drip-pod/';
    const connectionButtonVisibility = ref(false);
    const connectionButtonVisibilityTrigger = ref(undefined);
    const windowInnerHeight = window.innerHeight;
    const connectDripPod = () => {
      window.location.href = context.root.$systemDialog(
        SystemDialogMessage.BLUETOOTH_INTRODUCTION
      );
    };

    // 「DRIP POD YOUBIと連携する」ボタンの表示・非表示を切り替える
    const switchConnectionButtonVisibility = () => {
      const isPageTop = window.pageYOffset === 0;

      // 「コーヒータイムがもっと自由に〜」のセクションを過ぎたら追従するボタンを非表示（位置が戻ったら表示）
      const triggerPosition =
        connectionButtonVisibilityTrigger.value?.getBoundingClientRect().bottom;
      connectionButtonVisibility.value = connectionButtonVisibilityTrigger.value
        ? isPageTop || Math.ceil(triggerPosition) >= windowInnerHeight * 0.82
        : false;
    };

    const scroll = () => {
      switchConnectionButtonVisibility();
    };

    onMounted(() => {
      switchConnectionButtonVisibility();
    });

    onBeforeMount(() => {
      window.addEventListener('scroll', scroll);
      switchConnectionButtonVisibility();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scroll);
    });

    return {
      WORDS,
      MORE_BUTTON_HREF,
      connectDripPod,
      connectionButtonVisibility,
      connectionButtonVisibilityTrigger
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

$base12px: 3.2vw;
$base14px: 3.7333vw;
$base16px: 4.2667vw;
$base20px: 5.3333vw;
$base24px: 6.4vw;
$base32px: 8.5333vw;
$base64px: 17.0667vw;

.dp-introduction {
  // NOTE: 追従するボタンを下部でも表示する場合はコメントイン
  // padding-bottom: $base64px;
  background-color: #fff;
}

.dp-introduction-header {
  img {
    width: 100%;
    height: auto;
  }
}

.dp-introduction-footer {
  position: fixed;
  bottom: $base16px;
  left: 0;
  display: grid;
  place-items: center;
  padding-inline: $base16px;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.dp-introduction-connection-button {
  display: grid;
  place-items: center;
  width: 100%;
}

.dp-introduction-button {
  display: grid;
  place-items: center;
  margin-block: $base32px;
  width: 100%;
}

.dp-introduction-label-en {
  text-transform: uppercase;
}

.dp-introduction-section {
  padding: $base32px;
  color: variables.$dpBlack01;
  font-size: $base14px;
  line-height: 1.8;
  font-family: 'Noto Sans JP';
  font-weight: 500;
  overflow-wrap: break-word;

  &:nth-child(2n + 1) {
    background-color: variables.$dpGreyF1;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: $base16px;
  }

  img {
    width: 100%;
    height: auto;
  }

  figure {
    margin-block: $base32px;
    margin-inline: -$base32px;

    &:first-child {
      margin-top: -$base32px;
    }
  }

  &__note {
    font-size: $base12px;
    line-height: 1.5;
    font-weight: 400;
  }

  &__balloon {
    position: relative;
    margin-top: -$base64px;
    padding-block: $base24px;
    padding-inline: $base20px;
    background-color: #fff;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.dp-introduction-section-title {
  margin-bottom: $base32px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
