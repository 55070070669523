var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "dp-introduction" },
    [
      _vm._m(0),
      _c(
        "section",
        {
          ref: "connectionButtonVisibilityTrigger",
          staticClass: "dp-introduction-section",
        },
        [
          _vm._m(1),
          _vm._m(2),
          _c("p", [
            _vm._v(
              " プロの抽出レシピで、ひとつのカプセルから広がる様々な味わいに出会えます。 "
            ),
          ]),
          _vm._m(3),
        ]
      ),
      _vm._m(4),
      _c("section", { staticClass: "dp-introduction-section" }, [
        _vm._m(5),
        _vm._m(6),
        _vm._m(7),
        _vm._m(8),
        _vm._m(9),
        _vm._m(10),
        _vm._m(11),
        _vm._m(12),
        _vm._m(13),
        _vm._m(14),
        _c(
          "a",
          {
            directives: [
              {
                name: "ripple",
                rawName: "v-ripple",
                value: _vm.$utilities.getRippleOptions("light"),
                expression: "$utilities.getRippleOptions('light')",
              },
            ],
            staticClass: "dp-introduction-button",
            attrs: {
              href: _vm.$customUrlScheme.page(
                "external",
                "url=" + _vm.MORE_BUTTON_HREF
              ),
            },
          },
          [
            _c("img", {
              attrs: {
                src: "/assets/img/drip-pod/introduction/button_more.webp",
                alt: "Drip Podをもっと詳しく知る",
              },
            }),
          ]
        ),
      ]),
      _vm._m(15),
      _c("section", { staticClass: "dp-introduction-section" }, [
        _vm._m(16),
        _vm._m(17),
        _vm._m(18),
        _vm._m(19),
        _vm._m(20),
        _c("p", [
          _vm._v(
            " おいしさの秘密は、しっかりと“蒸らし”ができるカプセルの中に。プロのハンドドリップの技を再現した「湯温」「抽出速度」「蒸らし時間」により、ボタンひとつで本格的なコーヒーが淹れられます。 "
          ),
        ]),
        _vm._m(21),
        _vm._m(22),
        _vm._m(23),
        _c(
          "a",
          {
            directives: [
              {
                name: "ripple",
                rawName: "v-ripple",
                value: _vm.$utilities.getRippleOptions("light"),
                expression: "$utilities.getRippleOptions('light')",
              },
            ],
            staticClass: "dp-introduction-button",
            attrs: {
              href: _vm.$customUrlScheme.page(
                "external",
                "url=" + _vm.MORE_BUTTON_HREF
              ),
            },
          },
          [
            _c("img", {
              attrs: {
                src: "/assets/img/drip-pod/introduction/button_more.webp",
                alt: "Drip Podをもっと詳しく知る",
              },
            }),
          ]
        ),
        _vm._m(24),
      ]),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.connectionButtonVisibility
          ? _c("footer", { staticClass: "dp-introduction-footer" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple",
                      value: _vm.$utilities.getRippleOptions("light"),
                      expression: "$utilities.getRippleOptions('light')",
                    },
                  ],
                  staticClass: "dp-introduction-connection-button",
                  attrs: { type: "button" },
                  on: { click: _vm.connectDripPod },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/assets/img/drip-pod/introduction/button_connection.webp",
                      alt: "Drip Pod Youbiと連携する",
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "dp-introduction-header" }, [
      _c("figure", [
        _c("img", {
          attrs: {
            src: "/assets/img/drip-pod/introduction/pic_header.webp",
            alt: "きょうも、あしたも、ふだんを好い日に。DRIP POD YOUBI",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_coffee_time.webp",
          alt: "コーヒータイムがもっと自由になれば、日々の暮らしがもっと豊かになる。",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 「"),
      _c("span", { staticClass: "dp-introduction-label-en" }, [
        _vm._v("Drip Pod Youbi"),
      ]),
      _vm._v("」と「"),
      _c("span", { staticClass: "dp-introduction-label-en" }, [
        _vm._v("Coffee Creation"),
      ]),
      _vm._v(
        "」アプリを組み合わせれば、自分の好みやその日の気分にあわせて、あなたをエスコート。 "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" コーヒーの選び方をもっと自由に、"),
      _c("br"),
      _vm._v("味わいをさらに豊かにしてくれます。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "dp-introduction-section" }, [
      _c("h2", { staticClass: "dp-introduction-section-title" }, [
        _c("img", {
          attrs: {
            src: "/assets/img/drip-pod/introduction/title_professionals_recipe.webp",
            alt: "Professional's Recipe",
          },
        }),
      ]),
      _c("p", [
        _vm._v(
          " さまざまな抽出競技会でチャンピオンに輝くUCCのプロフェッショナルが監修したオリジナル抽出レシピです。 同じコーヒー豆（"
        ),
        _c("span", { staticClass: "dp-introduction-label-en" }, [
          _vm._v("Drip Pod"),
        ]),
        _vm._v(
          "専用カプセル）でも、 「蒸らし」「抽出時間」「注湯スピード」など異なる抽出レシピにすることで、様々に変化する味わいを楽しむことができます。 "
        ),
      ]),
      _c("p", [
        _c("span", { staticClass: "dp-introduction-label-en" }, [
          _c("span", { staticClass: "dp-introduction-label-en" }, [
            _vm._v("Coffee Creation"),
          ]),
        ]),
        _vm._v(
          "アプリを使用すると、 プロのオリジナル抽出レシピを気分やシーンに合わせて気軽に楽しむことができます。 "
        ),
      ]),
      _c("p", { staticClass: "dp-introduction-section__note" }, [
        _vm._v(" *PROFESSIONAL’s RECIPEは、"),
        _c("span", { staticClass: "dp-introduction-label-en" }, [
          _vm._v("Drip Pod Youbi"),
        ]),
        _vm._v("のオリジナル機能です。 "),
      ]),
      _c("figure", [
        _c("img", {
          attrs: {
            src: "/assets/img/drip-pod/introduction/pic_professional_recipe.webp",
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "dp-introduction-section__balloon" }, [
        _c("p", [
          _vm._v(
            " 私たちは、バリスタチャンピオンシップなどの競技会で、コーヒー豆の個性に合わせて“抽出レシピ”を変えることで、引き出したい味わいをコントロールしています。 "
          ),
        ]),
        _c("p", [
          _vm._v(
            " 甘みを引き出したいときは、高めの湯温で蒸らし時間を多めにとったり、雑味を減らしたいときは後半の抽出は低温でサッと淹れるなど様々なテクニックがあるんですよ。（UCCコーヒーアカデミー講師 村田果穂） "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_merit.webp",
          alt: "Coffee Creationでできること",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/pic_merit01.webp",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_merit01.webp",
          alt: "アプリ連携でプロの抽出レシピが楽しめる",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 同じカプセル（コーヒー豆）でも、抽出レシピによって、フルーティさを際立たせたり、さっぱりとした味わいで抽出したり、味わいに変化をもたせることができます。"
      ),
      _c("br"),
      _vm._v(
        " ひとつのカプセルから広がる味わい、広がるコーヒーの世界をお楽しみいただけます。 "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/pic_merit02.webp",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_merit02.webp",
          alt: "色々な選び方ができる",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " レシピ名から探す、カプセルから探す、レシピを監修した講師から探すなど、 "
      ),
      _c("span", { staticClass: "dp-introduction-label-en" }, [
        _vm._v("Coffee Creation"),
      ]),
      _vm._v(
        "アプリを使えば、様々な方法でレシピを探せます。さっそくお気に入りのレシピを見つけてみては？ "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/pic_merit03.webp",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_merit03.webp",
          alt: "お気に入りのプロのレシピをマシンに登録",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 抽出レシピを試すなかでお気に入りのものを見つけたら、 是非「"),
      _c("span", { staticClass: "dp-introduction-label-en" }, [
        _vm._v("Drip Pod Youbi"),
      ]),
      _vm._v("」本体に登録してみましょう。"),
      _c("br"),
      _vm._v(" アプリを立ち上げなくても、本体の「"),
      _c("span", { staticClass: "dp-introduction-label-en" }, [
        _vm._v("Favorite"),
      ]),
      _vm._v("」ボタンを押すだけでお気に入りの一杯が楽しめます。 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "dp-introduction-section" }, [
      _c("h2", { staticClass: "dp-introduction-section-title" }, [
        _c("img", {
          attrs: {
            src: "/assets/img/drip-pod/introduction/title_connection.webp",
            alt: "アプリと連携するには？",
          },
        }),
      ]),
      _c("p", [
        _vm._v(" スマートフォンと「"),
        _c("span", { staticClass: "dp-introduction-label-en" }, [
          _vm._v("Drip Pod Youbi"),
        ]),
        _vm._v("」を用意して、「"),
        _c("span", { staticClass: "dp-introduction-label-en" }, [
          _vm._v("Drip Pod"),
        ]),
        _vm._v("と連携する」ボタンを押しましょう！画面の説明に従い、「"),
        _c("span", { staticClass: "dp-introduction-label-en" }, [
          _vm._v("Drip Pod Youbi"),
        ]),
        _vm._v("」とアプリを連携してください。 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/pic_about.webp",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_about.webp",
          alt: "Drip Podとは？",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "dp-introduction-label-en" }, [
        _vm._v("Drip Pod"),
      ]),
      _vm._v(
        "（ドリップポッド）とは、コーヒーひと筋90年のUCCが手掛けた、カプセル式のドリップコーヒーシステムです。 "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/pic_easy.webp",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_easy.webp",
          alt: "プロのハンドドリップをボタンひとつで。",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/pic_tasty.webp",
          alt: "Coffee Belt（南緯25度から北緯25度の地域）の画像",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "dp-introduction-section-title" }, [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/title_tasty.webp",
          alt: "世界中を旅するように味わえる",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 熟練のコーヒー鑑定士が世界中の生産地から厳選したコーヒーを一杯分ずつ密封パック。"
      ),
      _c("br"),
      _vm._v(
        " 12種類の定番カプセルラインアップに加え、旬の味わいを楽しめる期間限定のスペシャルティコーヒーも楽しめます。"
      ),
      _c("br"),
      _vm._v(" きっとお好みの味が見つかるはずです。"),
      _c("br"),
      _vm._v(
        " また、シーンや気分にあわせて、紅茶やお茶もお楽しみいただけます。 "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", [
      _c("img", {
        attrs: {
          src: "/assets/img/drip-pod/introduction/logo_dp.webp",
          alt: "Drip Podのロゴ",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }